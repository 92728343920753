<template>
    <div>
      <div class="w-screen location-section-container lavish-location-banner relative space-top text-white-1">
        <div class="px-4 z-10 max-w-7xl text-center h-full flex flex-col gap-2 items-center justify-center mx-auto">
          <h2 class="font-historia text-yellow-2 tracking-wider text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl">Find us here</h2>
          <p class="font-century text-base mobileL:text-xl xl:text-3xl 2xl:text-3xl">We're making sure that there's a Lavish <br> Lashes store accessible to everyone</p>
  
          <div class="input-wrapper mt-2 w-4/5 mobileL:w-3/5 mx-auto">
              <input class="input-box font-bold text-xl mobileL:font-normal mobileL:text-base xl:text-xl 2xl:text-3xl" type="text" 
              placeholder="Search Branch" v-model="searchStr" @keyup.enter="searchBranch" :class="{ 'hide-bg': searchStr }">
          </div>
        </div>
      </div>
      <div class="w-full map-section bg-white-1" id="map" v-show="!shouldShowElement">
        <div class="max-w-screen-lg mobileL:max-w-screen-2xl 2xl:max-w-screen-3xl mx-auto px-5 py-8 xl:px-8 xl:py-12">
            <div class="map-container rounded-none mobileL:rounded-3xl">
                <Map :businessUnitId="$lavishLashesId" :branchToSearch.sync="branchProps"/>
            </div>
        </div>
      </div>
  
      <div class="w-full h-full pt-7 bg-white-1" id="location" v-show="shouldShowElement">
        <div class="max-w-screen-2xl 2xl:max-w-screen-3xl mx-auto px-5 pb-8 xl:px-8 xl:py-12">
            <div class="rounded-none">
                <MapMobile @branchesLength="handleBranchesLength" @toggleMap="handleToggleMap" :businessUnitId="$lavishLashesId" :branchToSearch.sync="branchProps" />
                <div class="pt-5 h-96" v-show="showMapLocation || this.branchesLength > 0">
                  <MapLocation :businessUnitId="$lavishLashesId" :branchToSearch.sync="branchProps"></MapLocation>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Map from '../../components/Map.vue'
  import MapLocation from '../../components/MapLocation.vue'
  import MapMobile from '../../components/MapMobile.vue';
  export default {
    components: {
        Map,
        MapLocation,
        MapMobile
    },
  
    data(){
        return {
            branches: [],
            searchStr: '',
            branchProps: '',
            windowWidth: window.innerWidth,
            receivedData:'',
            branchesData:'',
            branchesLength:'',
            showMapLocation: false,
        }
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
  
    computed: {
      shouldShowElement() {
        return this.windowWidth < 480;
      },
    },
    methods: {
      handleToggleMap(show) {
        this.showMapLocation = show;
      },
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
      handleBranchesLength(length) {
        this.branchesLength = length;
        this.showMapLocation = false;
      },
      searchBranch() {
        const currentSearch = this.searchStr;
        this.branchesLength = '';
        this.branchProps = null;
        this.$nextTick(() => {
          this.branchProps = currentSearch;
        });
      }
    }
  }
  </script>
  
  <style scoped>
  
  .location-section-container {
    height: 600px;
  }
  
  .space-top {
    margin-top: 90px;
  }
  
  .lavish-location-banner {
    background: url('../../assets/images/location-page/lavish-location-bg.png') no-repeat;
    background-size: 100% 100%;
  }
  
  .input-wrapper {
    position: relative;
  }
  
  .input-box {
    padding: 10px 50px 10px 30px;
    border: none;
    border-bottom: 2px solid #fefefe;;
    color: #fef6ef;
    width: 100%;
    background-color: transparent;
    text-align: center;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMzLjgwNDEgMjguMDE5M0wyNy44OTE3IDIyLjA5ODVMMjMuMzQ0MSAyMC4yMTY5QzI0Ljg1ODMgMTguMDYwNSAyNS42Njk1IDE1LjQ4NzkgMjUuNjY2NiAxMi44NTE3QzI1LjY2NjYgNS43NjUyMiAxOS45MDk2IDAgMTIuODMzMyAwQzUuNzU2OTggMCAwIDUuNzY1MjIgMCAxMi44NTE3QzAgMTkuOTM4MSA1Ljc1Njk4IDI1LjcwMzQgMTIuODMzMyAyNS43MDM0QzE1LjQ4ODcgMjUuNzA2NCAxOC4wNzg4IDI0Ljg4IDIwLjI0MyAyMy4zMzkyTDIyLjExNyAyNy44ODExTDI4LjAyOTEgMzMuODAyMkMyOC40MDgzIDM0LjE4MTkgMjguODU4NCAzNC40ODMyIDI5LjM1MzgzIDM0LjY4ODdDMjkuODQ5MiAzNC44OTQyIDMwLjM4MDIgMzUgMzAuOTE2NSAzNUMzMS40NTI3IDM1IDMxLjk4MzcgMzQuODk0MyAzMi40NzkxIDM0LjY4ODhDMzIuOTc0NiAzNC40ODMzIDMzLjQyNDcgMzQuMTgyMSAzMy44MDM5IDMzLjgwMjRDMzQuMTgzMSAzMy40MjI3IDM0LjQ4MzkgMzIuOTcxOSAzNC42ODkxIDMyLjQ3NThDMzQuODk0MyAzMS45Nzk2IDM1IDMxLjQ0NzkgMzUgMzAuOTEwOUMzNSAzMC4zNzM5IDM0Ljg5NDQgMjkuODQyMSAzNC42ODkyIDI5LjM0NkMzNC40ODQgMjguODQ5OCAzNC4xODMzIDI4LjM5OSAzMy44MDQxIDI4LjAxOTNaTTIuMzMzMzMgMTIuODUxN0MyLjMzMzMzIDcuMDUzODIgNy4wNDM3MyAyLjMzNjY3IDEyLjgzMzMgMi4zMzY2N0MxOC42MjI5IDIuMzM2NjcgMjMuMzMzMyA3LjA1MzgyIDIzLjMzMzMgMTIuODUxN0MyMy4zMzMzIDE4LjY0OTUgMTguNjIyOSAyMy4zNjY3IDEyLjgzMzMgMjMuMzY2N0M3LjA0MzczIDIzLjM2NjcgMi4zMzMzMyAxOC42NDk1IDIuMzMzMzMgMTIuODUxN1pNMzIuMTU0MSAzMi4xNDk5QzMxLjgyNTYgMzIuNDc4MSAzMS4zODA2IDMyLjY2MjQgMzAuOTE2NiAzMi42NjI0QzMwLjQ1MjYgMzIuNjYyNCAzMC4wMDc2IDMyLjQ3ODEgMjkuNjc5MSAzMi4xNDk5TDI0LjA5NTggMjYuNTU4NkwyMi4zNTM1IDIyLjMzNTRMMjYuNTcwOCAyNC4wODAyTDMyLjE1NDIgMjkuNjcxNUMzMi40ODE5IDMwLjAwMDUgMzIuNjY1OSAzMC40NDYyIDMyLjY2NTggMzAuOTEwOENDMzIuNjY1OCAzMS4zNzU0IDMyLjQ4MTggMzEuODIxIDMyLjE1NDEgMzIuMTQ5OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=') no-repeat;
    background-size: 25px 25px;
    background-position: calc(75% - 15px) center;
  }

  .input-box.hide-bg {
    background: none;
  }
  
  .input-box:focus {
    outline: none;
  }
  
  .input-box::placeholder {
      color: #fefefe;
      font-size: 1.3rem;
  }
  
  .map-container {
    height: 800px;
  }
  
  @media (max-width: 480px) {
    .space-top {
      margin-top: 60px;
    }
    .location-section-container {
      height: 45vh;
    }
   
    .map-section {
      height: 480px;
    }
  
    .map-container {
      height: 420px;
    }
    
    .input-box {
      background-size: 21px 21px;
      background-position: calc(85%) center;
    }
  }

  @media (min-width: 1024px) {
    .input-box{
      background-size: 25px 25px;
      background-position: calc(75% - 40px) center;
    }
  }
  
  @media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }
  
    .location-section-container {
      height: 800px;
    }

    .input-box {
      background-size: 30px 30px;
      background-position: calc(75% - 40px) center;
    }
  
    .input-box::placeholder {
      font-size: 1.8rem;
    }
  }
  
  @media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }
  
    .location-section-container {
      height: 110vh;
    }
  
    .input-box::placeholder {
      font-size: 2rem;
    }
  }
  </style>